import React from 'react';

import { Props } from './NamecheckNotification.interface';

import {
  namecheckNotification___home,
  namecheckNotification as namecheckNotificationClass,
  namecheckNotification__icon,
  namecheckNotification__companyName,
  namecheckNotification__description,
  namecheckNotification___red,
  namecheckNotification___green,
} from './NamecheckNotification.scss';

const getNotificationColour = (status: string) => {
  switch (status.toLowerCase()) {
    case 'red':
      return namecheckNotification___red;
    case 'green':
      return namecheckNotification___green;
    default:
      return '';
  }
};

export const NamecheckNotification = ({ notification, home, status }: Props): JSX.Element => {
  const { iconSrc, companyName, description } = notification;

  const styles = {
    home: home ? namecheckNotification___home : '',
    status: status ? getNotificationColour(status) : '',
  };

  return (
    <div className={`${namecheckNotificationClass} ${styles.home} ${styles.status}`}>
      <img src={iconSrc} alt={`Icon for ${status.toLowerCase()} status.`} className={namecheckNotification__icon} />
      <h3 className={namecheckNotification__companyName}>{companyName}</h3>
      <div className={namecheckNotification__description}>{description}</div>
    </div>
  );
};
