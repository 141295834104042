// extracted by mini-css-extract-plugin
export var namecheck = "Namecheck--namecheck--Nq2i7";
export var namecheck___home = "Namecheck--namecheck___home--qvn2t";
export var namecheck__button = "Namecheck--namecheck__button--MGh7q";
export var namecheck__button___alt = "Namecheck--namecheck__button___alt--3DsKw";
export var namecheck__button___success = "Namecheck--namecheck__button___success--9+8a0";
export var namecheck__form = "Namecheck--namecheck__form--SX3GY";
export var namecheck__input = "Namecheck--namecheck__input--HorcD";
export var namecheck__name = "Namecheck--namecheck__name--zVvpR";
export var namecheck__namecheck = "Namecheck--namecheck__namecheck--phLLD";
export var namecheck__reset = "Namecheck--namecheck__reset--XkJ1R";
export var namecheck__search = "Namecheck--namecheck__search--C0Hhn";
export var namecheck__spinner = "Namecheck--namecheck__spinner--Wfc3C";
export var namecheck__success = "Namecheck--namecheck__success--Mr+4l";
export var tkMyriadProSemiCondensed = "Namecheck--tk-myriad-pro-semi-condensed--mBKWM";