import React from 'react';

import Namecheck from 'components/directus/namecheck/Namecheck';
import OrderSteps from 'components/directus/order-steps/OrderSteps';
import packagesHook from 'hooks/packages/packages.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './NameCheckPackages.interface';

import { namecheckContainer, namecheckContainer__title, namecheckContainer__name } from './NameCheckPackages.scss';

export default ({ slug }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, packagesHook());

  return (
    <>
      <OrderSteps currentStep={1} />
      <div className={namecheckContainer}>
        <h1 className={namecheckContainer__title}>
          You have chosen the <span className={namecheckContainer__name}>{node.name} Package</span>.<br />
          Now choose your company name.
        </h1>
        <Namecheck packageSlug={node.slug} checkoutPath={node.checkout_path} />
      </div>
    </>
  );
};
