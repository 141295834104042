import { graphql, useStaticQuery } from 'gatsby';
import { DirectusNamecheck, Namecheck } from './namecheck.interface';

export default (): Namecheck => {
  const { directusNamecheck } = useStaticQuery<DirectusNamecheck>(graphql`
    query {
      directusNamecheck {
        placeholder_text
        placeholder_text_mobile
        continue_url
        button_text
      }
    }
  `);

  return directusNamecheck;
};
